import msaIcon from '../assets/companyLogos/msa.png';
import hsIcon from '../assets/companyLogos/hs.png';
import swIcon from '../assets/companyLogos/sw.png';
import selmerIcon from '../assets/companyLogos/selmer.png';
import cvIcon from '../assets/companyLogos/cvpartner.png';
import wemble from '../assets/companyLogos/wemble.svg';
import { CompanyDto } from 'utils/wemble-api.generated';

type OrganizationSettings = {
  name: string;
  icon: string;
  disableProjects?: boolean;
  disableProjectManager?: boolean;
  enableProjectStages?: boolean;
  disableAbsencePlanner?: boolean;
  absencePlannerviewOnly?: boolean;
  projectInvoicing?: boolean;
  disableCompetences?: boolean;
  disableCompetencesPriviligies?: boolean;
  disableCompetencesEditSelf?: boolean;
  disableMessage?: boolean;
  allOfficesDefault?: boolean;
  allGroupsDefault?: boolean;
  projectsAllGroups?: boolean;
  enableUtilizationView?: boolean;
  mattersTimeperiod?: number;
  hideUpdateRemindersSetting?: boolean;
  enableDetailedViewHours?: boolean;
  enableDetailedViewComments?: boolean;
  enableMatterPM?: boolean;
  expandComment?: boolean;
  enableCustomHtml?: boolean;

  enableNewForecasting?: boolean;
  disableWFH?: boolean;
  elseWhereOption?: boolean;

};

export default function (company: CompanyDto | string | null | undefined): OrganizationSettings {
  const companyId = typeof company === 'string' ? company : company?._id;
  switch (companyId) {
    case '5bf56c679d6f50bd0e67d6c0':
      return {
        name: 'Mannheimer Swartling',
        icon: msaIcon,
        expandComment: true

      };

    case '5da0c1370b30f993a68d898f':
      return {
        name: 'Hannes Snellman',
        icon: hsIcon,
        enableUtilizationView: true,
        enableMatterPM: true,
        expandComment: true,
        enableDetailedViewHours: true,
        enableDetailedViewComments: true,
        mattersTimeperiod: 14,
        disableProjects: false,
        enableProjectStages: true,
        disableCompetences: true,
        enableNewForecasting: true,
        elseWhereOption: true


      };
    case '5e4d42f50fc8ec2139341a39':
      return {
        name: 'Selmer',
        icon: selmerIcon,
        disableProjects: true,
        disableAbsencePlanner: true,
        disableCompetencesPriviligies: true,
        expandComment: true


      };

    case '5e5cd5ec4a51560674ab9188':
      //SW GBG
      return {
        name: 'Setterwalls',
        icon: swIcon,
        expandComment: true,
      };

    case '5ebbc876321b1e843727b00f':
      return {
        name: 'CV Partner',
        icon: cvIcon,
        expandComment: true,
      };

    case '6011c36f110b63a4a08b6aab':
      return {
        name: 'Cirio',
        icon: wemble,
        expandComment: true,
      };

    case '6239ed6a8398122b078e2019':
      return {
        name: 'Élan Zaak',
        icon: wemble,
        disableProjectManager: true,
        enableProjectStages: true,
        projectInvoicing: true,
        expandComment: true,
      };
    case '62947730ce5f37dea9c5db79':
      return {
        name: 'Kvale',
        disableProjects: true,
        icon: wemble,
        allOfficesDefault: true,
        expandComment: true
      };
    case '63491b42fef920f504b85c93':
      return {
        name: 'MAQS',
        disableProjects: true,
        icon: wemble,
        disableMessage: true,
        allOfficesDefault: true,
        expandComment: true,
        elseWhereOption: true
      };
    case '64ff3e804ec954850f158026':
      return {
        name: 'Faber',
        icon: wemble,
        allOfficesDefault: true,
        allGroupsDefault: true,
        projectsAllGroups: true,
        hideUpdateRemindersSetting: true,
        disableCompetencesPriviligies: true,
        disableCompetencesEditSelf: true,
        enableCustomHtml: true,
        expandComment: true,
        enableNewForecasting: true,
        disableWFH: true

      };
    case '650197f48213ce0e6b0e5045':
      return {
        name: 'Synch',
        icon: wemble,
        allOfficesDefault: false,
        allGroupsDefault: false,
        projectsAllGroups: true,
        absencePlannerviewOnly: true,
        enableProjectStages: true,
        enableUtilizationView: true,
        enableDetailedViewHours: true,
        expandComment: true,
        mattersTimeperiod: 7,
        enableNewForecasting: true,

      };
    case '657662a326e3fd00142a815f':
      return {
        name: 'Falk Steadman LLP',
        icon: wemble,
        disableProjects: true,
        enableUtilizationView: true,
        expandComment: true,
        enableDetailedViewComments: true,
        enableDetailedViewHours: true,
        disableMessage: true,
        enableCustomHtml: true,
        mattersTimeperiod: 7,
        enableNewForecasting: true,
        disableWFH: true,

      };
    case '65f779d34b718376e412163f':
      return {
        name: 'Brækhus',
        icon: wemble,
        expandComment: true,
        disableProjects: true,
        elseWhereOption: true
      };
    case '667d4e911ec312156ecad104':
      return {
        name: 'Hannes Snellman (Sweden)',
        icon: wemble,
        expandComment: true,
        disableProjects: true,
      };
    default:
      return {
        name: 'Wemble',
        expandComment: true,
        icon: wemble,
      };
  }
}
